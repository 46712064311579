:root {
  --dark-0: #000;
  --dark-1: #1e1e1e;
  --dark-2: #2e2e2e;
  --dark-3: #3e3e3e;
  --dark-4: #4e4e4e;
  --dark-5: #5e5e5e;
  --dark-6: #6e6e6e;
  --dark-7: #7e7e7e;
  --dark-8: #8e8e8e;

  --light-0: #fff;
  --light-1: #e1e1e1;
  --light-2: #d1d1d1;
  --light-3: #c1c1c1;
  --light-4: #b1b1b1;
  --light-5: #a1a1a1;
  --light-6: #919191;
  --light-7: #818181;
  --light-8: #717171;

  --code-keyword-light: #bd2864;
  --code-keyword-dark: #bd2864;

  --code-string-light: #297a3a;
  --code-string-dark: #62c073;

  --code-function-light: #0068d6;
  --code-function-dark: #52a8ff;

  --code-constant-dark: #f8c555;
  --code-constant-light: #f08d49;

  --code-operator-dark: #67cdcc;
  --code-operator-light: #016464;

  --link-dark: #2563eb;
  --link-light: #3b82f6;

  --dark-0-60: rgba(0, 0, 0, 0.6);
  --light-0-60: rgba(255, 255, 255, 0.6);

  --text-f: 'Inter';
  --title-f: 'Inter';

  overflow-y: scroll;
}

:root[data-theme='dark'] {
  color-scheme: dark;

  --main: var(--dark-0);
  --main-hover: var(--dark-1);
  --secondary: var(--dark-1);
  --secondary-hover: var(--dark-2);
  --tertiary: var(--dark-2);
  --tertiary-hover: var(--dark-3);
  --accent: var(--dark-3);
  --accent-hover: var(--dark-4);

  --main-text: var(--light-0);
  --main-text-hover: var(--light-1);
  --secondary-text: var(--light-1);
  --secondary-text-hover: var(--light-2);
  --tertiary-text: var(--light-2);
  --tertiary-text-hover: var(--light-3);
  --accent-text: var(--light-3);
  --accent-text-hover: var(--light-4);

  --border: var(--dark-2);
  --border-hover: var(--light-7);

  --main-60: var(--dark-0-60);

  --code-keyword: var(--code-keyword-dark);
  --code-string: var(--code-string-dark);
  --code-function: var(--code-function-dark);
  --code-constant: var(--code-constant-dark);
  --code-operator: var(--code-operator-dark);
  --link: var(--link-light);
}

:root[data-theme='light'] {
  color-scheme: light;

  --main: var(--light-0);
  --main-hover: var(--light-1);
  --secondary: var(--light-1);
  --secondary-hover: var(--light-2);
  --tertiary: var(--light-2);
  --tertiary-hover: var(--light-3);
  --accent: var(--light-3);
  --accent-hover: var(--light-4);

  --main-text: var(--dark-0);
  --main-text-hover: var(--dark-1);
  --secondary-text: var(--dark-1);
  --secondary-text-hover: var(--dark-2);
  --tertiary-text: var(--dark-2);
  --tertiary-text-hover: var(--dark-3);
  --accent-text: var(--dark-3);
  --accent-text-hover: var(--dark-4);

  --border: var(--light-1);
  --border-hover: var(--dark-8);

  --main-60: var(--light-0-60);

  --code-keyword: var(--code-keyword-light);
  --code-string: var(--code-string-light);
  --code-function: var(--code-function-light);
  --code-constant: var(--code-constant-light);
  --code-operator: var(--code-operator-light);
  --link: var(--link-dark);
}


* {
  transition-duration: 300ms;
  transition-property: background, border-color, opacity, box-shadow, top, bottom, left, margin, transform;
  box-sizing: border-box;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

body {
  margin: 0;
  font-family: "Inter", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main);
  color: var(--main-text);
  /*font-weight: 400;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.hidden{
  visibility: hidden;
}




